/*

@mui/icons-material
@emotion/styled
@emotion/react
@mui/material
react-router-dom

*/

@import url("https://fonts.googleapis.com/css2?family=Ubuntu&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lilita+One&display=swap");

body {
  box-sizing: border-box;
  overflow-x: hidden;
  max-width: 100vw;
  font-family: "Ubuntu", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

select {
  width: 234px;
  height: 44px;
  margin-bottom: 10px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.25);
  background: #fff;
  color: rgba(0, 0, 0, 0.65);
  padding-left: 10px;
  border-radius: 4px;
  font-family: "Ubuntu";
}

.AppBar {
  backdrop-filter: blur(5px);
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.4);
}

.nav-logo {
  width: 64px;
  height: 60px;
  margin-left: 75px;
}

.nav-title {
  color: #000;
  font-family: "Lilita One", sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 22px;
}

.carousel-item {
  width: 100vw;
  height: 100vh;
}

.carousel-caption {
  position: absolute;
  bottom: 45px;
  padding: 20px;
  width: auto;
  text-align: center;
  backdrop-filter: blur(3px);
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.section-title {
  text-align: center;
  backdrop-filter: blur(3px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  padding: 10px;
  width: fit-content;
}

.client-image {
  width: 200px;
  height: 125px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  margin-top: 120px;
}

.tes-image {
  width: 17rem;
  height: 20rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

.call-buttons {
  position: absolute;
  bottom: 15px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  z-index: 9999;
}

.center {
  position: absolute;
  top: 30%;
  left: 50%;
  translate: -50%;
}

.button {
  font-family: "Ubuntu", sans-serif;
}

.button:hover {
  color: #ff7800;
}

.resp {
  display: none;
}

.loginSection {
  width: 100vw;
  overflow: hidden;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgb(106, 169, 121) 0%,
    rgb(76, 76, 199) 35%,
    rgba(0, 212, 255, 1) 100%
  );
}

.loginSection .outlet-container {
  width: 100vw;
  overflow: hidden;
  background: rgba(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(106, 169, 121) 0%,
    rgba(76, 76, 199) 35%,
    rgba(0, 212, 255, 1) 100%
  );
}

.loginBox {
  backdrop-filter: blur(10px);
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.circle1 {
  width: 400px;
  height: 400px;
  position: absolute;
  top: -100px;
  left: 350px;
  background: rgb(76, 76, 199);
  border-radius: 100%;
  backdrop-filter: blur(5px);
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.circle2 {
  width: 600px;
  height: 600px;
  position: absolute;
  bottom: -300px;
  left: 550px;
  background: rgb(76, 76, 199);
  border-radius: 100%;
  backdrop-filter: blur(5px);
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.75);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.contentBox {
  backdrop-filter: blur(5px);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.backv {
  margin-left: -20rem;
  width: 150vw;
  height: 150vh;
}

.layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.35);
  z-index: 99;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.content h1 {
  color: #000;
  font-size: 1.5rem;
  font-family: "Ubuntu";
  font-weight: 900;
}

.content h2 {
  color: #000;
  font-size: 1.8rem;
  width: 50rem;
}

.gradient_background {
  background: darkgray;
  height: 35vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5rem;
}

.home_service {
  position: relative;
  padding-top: 25px;
  width: 100vw;
  height: 155vh;
  background: lightgray;
  z-index: 999;
}

.home_client {
  position: relative;
  padding-top: 50px;
  padding-bottom: 25px;
  width: 100vw;
  height: auto;
  background: lightslategray;
  z-index: 999;
}

.main_service {
  position: relative;
  padding-top: 100px;
  width: 100vw;
  height: 165vh;
  background: #1a2b3c;
  z-index: 999;
}

.home_strength {
  position: relative;
  padding-top: 25px;
  width: 100vw;
  height: auto;
  background: darkgrey;
  padding-bottom: 1rem;
}

.home_team {
  position: relative;
  padding-top: 25px;
  width: 100vw;
  height: 75vh;
  background: darkgray;
}

.testi_car {
  padding: 0.5rem;
  max-width: 100vw;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
}

.request_call {
  position: relative;
  width: 100vw;
  height: 20vh;
  background: lightgray;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.quote {
  font-family: "Ubuntu";
  font-size: 1.25rem;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
}

.playstore_div {
  position: relative;
  width: 100vw;
  height: 20vh;
  background: lightgray;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  z-index: 9999;
}

.playstore {
  font-family: "Ubuntu";
  font-size: 1.25rem;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
}

.footer_main {
  position: relative;
  width: 100vw;
  background: dimgray;
  z-index: 999;
}

.footer {
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: first baseline;
  justify-content: space-evenly;
  gap: 8rem;
  padding-top: 50px;
  z-index: 999;
}

.chat_div {
  position: fixed;
  right: 10px;
  bottom: 50px;
  z-index: 9999;
}

.chat_button {
  position: fixed;
  bottom: -25px;
  font-family: "Ubuntu";
  font-size: 2.5rem;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  z-index: 999;
}

.about_div {
  position: relative;
  width: 100vw;
  height: auto;
  padding: 10rem;
  padding-top: 5rem;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.35);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  background: lightslategray;
}

.about_content {
  padding: 1rem;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.35);
  background: transparent;
  backdrop-filter: blur(10px);
}

.gallery_div {
  max-width: 100vw;
  height: auto;
  padding: 8rem;
  padding-top: 50px;
  padding-bottom: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 2.5rem;
  background: lightslategray;
}

.home_division {
  position: relative;
  padding-top: 100px;
  width: 100vw;
  height: 150vh;
  background: url(https://cdn.pixabay.com/photo/2015/08/14/07/46/gradient-888101_640.jpg);
  background-size: cover;
  background-attachment: fixed;
  z-index: 999;
}

.division_img {
  margin-top: 6.5rem;
  width: 16rem;
  height: 12rem;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.client_img {
  width: 16rem;
  height: 12rem;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.career_div {
  position: relative;
  width: 100vw;
  height: 85vh;
  padding: 2.5rem;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.35);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background: lightslategray;
}

.career_content {
  width: 30vw;
  height: 20rem;
  padding: 2.5rem;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.career_content_div {
  width: auto;
  padding: 1.5rem;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.55);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.gallery_img {
  width: 15rem;
  height: 15rem;
  box-shadow: 2px 3px 4px rgba(0,0,0,.25);
  border-radius: 5px;
  cursor: pointer;
}

.gallery_img:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 600px) {
  body {
    overflow-x: hidden;
    width: 100vw;
  }
  .nav-logo {
    margin-left: 0;
    width: 44px;
    height: 44px;
  }

  .nav-title {
    font-size: 1rem;
    font-weight: 400;
    width: auto;
  }
  .carousel-item {
    height: 75vh;
  }
  .call-buttons {
    height: 5vh;
    top: 125px;
  }
  .viewD {
    display: none;
  }
  .viewM {
    position: absolute;
    top: 60px;
    left: -16px;
    right: 0;
    margin: 0;
    padding: 0;
    background: rgba(255, 255, 255, 0.75);
    color: #000;
    width: 100vw;
    height: 55vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    z-index: 999;
  }

  .viewM .button {
    position: relative;
    top: 2px;
    left: 30px;
  }

  .resp {
    position: absolute;
    right: 0.25rem;
    color: #000;
    display: block;
  }

  .circle1 {
    width: 250px;
    height: 250px;
    top: -50px;
    left: 50px;
  }
  .circle2 {
    width: 200px;
    height: 200px;
    bottom: -50px;
    left: 100px;
  }

  .client-image {
    width: 320px;
    height: 130px;
  }

  .home_service {
    height: auto;
    padding: 1.5rem;
  }

  .home_client {
    padding: 25px;
  }

  .main_service {
    height: auto;
    padding: 1.5rem;
  }

  .home_strength {
    height: auto;
    padding: 1.5rem;
  }

  .home_team {
    height: auto;
    padding: 1.5rem;
  }

  .testi_car {
    width: 100vh;
    height: auto;
    margin: 5rem;
  }

  .request_call {
    height: 20vh;
    flex-direction: column;
  }

  .quote {
    font-family: "Ubuntu";
    font-size: 0.85rem;
    font-weight: 100;
    text-align: center;
    text-transform: uppercase;
    margin-top: 15px;
  }

  .playstore_div {
    height: 20vh;
    flex-direction: column;
  }

  .playstore {
    font-family: "Ubuntu";
    font-size: 0.85rem;
    font-weight: 100;
    text-align: center;
    text-transform: uppercase;
    margin-top: 15px;
  }

  .footer_main {
    height: auto;
  }

  .footer {
    align-items: flex-start;
    flex-direction: column;
    gap: 1;
    padding: 3rem;
  }

  .about_content {
    width: auto;
    height: auto;
  }

  .about_div {
    height: auto;
    padding: 1.5rem;
    gap: 1rem;
    padding-bottom: 3rem;
  }

  .home_division {
    height: auto;
    padding: 1.5rem;
  }

  .career_content {
    flex-direction: column;
    width: 75vw;
  }

  .career_content_div {
    flex-direction: column;
    width: 85vw;
  }

  .career_div {
    height: auto;
    padding: 1.5rem;
  }

  .gradient_background {
    height: 25vh;
  }
  .cl_im {
    width: 100vw;
    margin-right: 1.5rem;
  }

  .tes_tes {
    margin-right: 1rem;
    margin-left: 1rem;
    width: 100vw;
  }

  .tes-image {
    width: 17rem;
  }

  .gallery_div {
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }
}
